<template>
    <header class="fixed w-full top-0 p-4 sm:px-8 flex items-center transition-colors z-50" :class="{ 'bg-secondary': windowIsScrolled }" ref="header" id="page-header">
        <a :href="homepageUrl" class="flex-none block">
            <svg xmlns="http://www.w3.org/2000/svg" class="block h-8 w-8" :class="{ 'fill-primary-50': !windowIsScrolled, 'fill-white': windowIsScrolled }">
                <use :href="iconHref('logomark')" />
            </svg>
            <span class="sr-only">{{ homepageLabel }}</span>
        </a>
        <PopoverGroup as="ul" class="flex gap-4 ml-auto items-center">
            <li class="hidden sm:block" v-if="contactPhone"><a :href="'tel:' + contactPhone" class="text-white hover:underline p-2 focus:outline focus:outline-white focus:outline-offset-2 rounded-sm">{{ contactPhone }}</a></li>
            <li class="hidden sm:block" v-if="contactEmail"><a :href="'mailto:' + contactEmail" class="text-white hover:underline p-2 focus:outline focus:outline-white focus:outline-offset-2 rounded-sm">{{ contactEmail }}</a></li>
            <li class="hidden sm:block" v-if="headerLinks && headerLinks.length > 0">
              <a v-for="link in headerLinks" :href="link.href" :target="link.target"
                 class="text-white hover:underline p-2 focus:outline focus:outline-white focus:outline-offset-2 rounded-sm">
                {{ link.title }}
              </a>
            </li>
            <Popover as="li" v-if="languages && languages.length > 1">
                <PopoverButton class="flex items-center gap-2 text-white hover:underline p-2 focus:outline focus:outline-white focus:outline-offset-2 rounded-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-white">
                        <use :href="iconHref('globe')" />
                    </svg>
                    <span>
                        <span class="sr-only">{{languagesLabel}}</span>
                        <abbr :title="currentLanguage.name" aria-current="true" class="no-underline">{{currentLanguage.abbreviation}}</abbr>
                    </span>
                </PopoverButton>
                <PopoverPanel class="w-full fixed left-0 -z-10 bg-secondary top-0" :style="headerHeightPadding">
                    <content-container class="pt-4 pb-8">
                        <ul class="grid grid-cols-3 lg:grid-cols-5 gap-2 md:w-4/5 md:mx-auto">
                            <li v-for="(language, index) in languages">
                                <a :href="language.href" class="flex gap-2 items-center text-white group outline-none hover:underline focus:underline">
                                    <span class="block rounded-full border-white border-2 ui-selected:border-4 group-focus:outline group-focus:outline-white group-focus:outline-offset-2 group-focus:outline-2">
                                        <responsive-image :src="language.flag" :width="40" :height="40" class="w-10 h-10 rounded-full"></responsive-image>
                                    </span>
                                    <span class="hidden sm:block">{{ language.name }}</span>
                                    <span class="sm:hidden">{{ language.abbreviation }}</span>
                                </a>
                            </li>
                        </ul>
                    </content-container>
                </PopoverPanel>
            </Popover>
            <Popover as="li" v-if="searchPage">
                <PopoverButton class="rounded-full p-2 hover:bg-white group focus:outline focus:outline-white focus:outline-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="block ui-open:hidden h-6 w-6 fill-white group-hover:fill-secondary" aria-hidden="true">
                        <use :href="iconHref('search')" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" class="hidden ui-open:block h-6 w-6 fill-white group-hover:fill-secondary" aria-hidden="true">
                        <use :href="iconHref('close')" />
                    </svg>
                    <span class="sr-only">{{searchLabel}}</span>
                </PopoverButton>
                <Transition
                    enter-active-class="transition"
                    enter-from-class="opacity-0 -translate-y-1/2"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                <PopoverPanel class="w-full fixed left-0 -z-10 bg-secondary top-0" :style="headerHeightPadding">
                    <content-container>
                        <form class="pt-4 pb-8" :action="searchPage" method="get">
                            <div class="relative">
                                <input type="search" name="term" class="search-bar w-full rounded-full py-4 px-8 border-0 focus:outline focus:outline-white focus:outline-offset-2" />
                                <button type="submit" class="absolute top-1/2 right-2 -translate-y-1/2 p-2 rounded-full hover:bg-primary focus:outline focus:outline-primary focus:outline-offset-2 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-primary group-hover:fill-white">
                                        <use :href="iconHref('search')" />
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </content-container>
                </PopoverPanel>
                </Transition>
            </Popover>
            <Popover as="li">
                <PopoverButton class="rounded-full p-2 hover:bg-white group focus:outline focus:outline-white focus:outline-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="block ui-open:hidden h-6 w-6 fill-white group-hover:fill-secondary">
                        <use :href="iconHref('menu')" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" class="hidden ui-open:block h-6 w-6 fill-white group-hover:fill-secondary">
                        <use :href="iconHref('close')" />
                    </svg>
                    <span class="sr-only">{{menuLabel}}</span>
                </PopoverButton>
                <Transition
                    enter-active-class="transition"
                    enter-from-class="opacity-0 -translate-y-1/2"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                <PopoverPanel class="h-screen w-full fixed left-0 -z-10 bg-secondary top-0" :style="headerHeightPadding">
                    <content-container class="h-full overflow-auto py-4 md:py-8 lg:py-32 md:flex">
                        <nav class="md:w-1/2 lg:w-3/5 relative md:pr-8 lg:pr-16">
                            <PopoverGroup as="ul" class="lg:w-1/2 flex flex-col gap-4">
                                <Popover as="li" v-for="link in navigation" v-slot="{ open }" ref="submenu">
                                    <template v-if="link.subNavigation && link.subNavigation.length > 0">
                                        <PopoverButton class="text-white text-lg text-left hover:underline focus:underline outline-none flex gap-2 group">
                                            {{ link.title }}
                                            <svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-8 h-8 group-aria-expanded:rotate-180">
                                                <use :href="iconHref('chevronRight')" />
                                            </svg>
                                        </PopoverButton>
                                        <Transition
                                            enter-active-class="transition"
                                            enter-from-class="opacity-0"
                                            enter-to-class="opacity-100">
                                            <PopoverPanel class="lg:absolute lg:top-0 lg:bottom-0 lg:right-0 lg:w-1/2 flex flex-col gap-4 mt-4 lg:mt-0" as="ul">
                                              <li v-for="sublink in link.subNavigation">
                                                  <a :href="sublink.href" :target="sublink.target" class="text-white hover:underline focus:underline outline-none">{{ sublink.title }}</a>
                                              </li>
                                              <li class="my-6">
                                                <a :href="link.href" :target="link.target" class="text-white hover:underline focus:border-white outline-none">{{viewAllLabel}}</a>
                                              </li>
                                            </PopoverPanel>
                                        </Transition>
                                    </template>
                                    <a :href="link.href" :target="link.target" class="text-white text-lg text-left hover:underline focus:underline outline-none" v-else>{{ link.title }}</a>
                                </Popover>
                            </PopoverGroup>
                        </nav>
                        <div v-if="headerLinks && headerLinks.length > 0" class="md:hidden border-t md:border-t-0 md:border-l border-secondary-40 pt-8 mt-8">
                          <ul>
                            <li class="" >
                              <a v-for="link in headerLinks" :href="link.href" :target="link.target"
                                 class="text-white text-lg hover:underline p-2 focus:outline focus:outline-white focus:outline-offset-2 rounded-sm">
                                {{ link.title }}
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="md:w-1/2 lg:w-2/5 pt-8 mt-8 md:pt-0 md:mt-0 md:pl-8 lg:pl-16 border-t md:border-t-0 md:border-l border-secondary-40">
                            <div class="prose text-white mb-4">
                                <slot name="default">
                                </slot>
                            </div>
                            <ul class="flex flex-col gap-2 mb-4">
                                <li v-if="contactEmail"><a :href="'tel:' + contactPhone" class="text-white text-lg">{{contactPhone}}</a></li>
                                <li v-if="contactPhone"><a :href="'mailto:' + contactEmail" class="text-white text-lg">{{contactEmail}}</a></li>
                            </ul>
                            <div class="mt-4" v-if="contactForm">
                                <nav-button type="anchor" color="secondary" :inverse="true" :href="contactForm">{{ contactFormLabel }}</nav-button>
                            </div>
                        </div>
                    </content-container>
                </PopoverPanel>
                </Transition>
            </Popover>
        </PopoverGroup>
    </header>
    <div v-if="$slots.breadcrumb" :style="breadcrumbTopOffset" class="hidden md:block absolute left-0 w-full mt-5 z-40">
      <slot name="breadcrumb"></slot>
    </div>
</template>

<script setup>
import debounce from 'lodash/debounce'
import {Popover, PopoverButton, PopoverGroup, PopoverPanel} from '@headlessui/vue'
import ResponsiveImage from '../responsive-image'
import NavButton from '../nav-button'
import ContentContainer from '../content-container'</script>

<script>
import IconLogomark from '../../../assets/icons/logomark.svg'
import IconChevronRight from '../../../assets/icons/chevron-right.svg'
import IconSearch from '../../../assets/icons/search.svg'
import IconGlobe from '../../../assets/icons/globe.svg'
import IconMenu from '../../../assets/icons/menu.svg'
import IconClose from '../../../assets/icons/close.svg'



const iconMap = {
    'logomark': IconLogomark,
    'chevronRight': IconChevronRight,
    'search': IconSearch,
    'globe': IconGlobe,
    'menu': IconMenu,
    'close': IconClose
}

export default {
    name: 'app-header',
    props: {
        headerLinks: { type: Array },
        navigation: { type: Array },
        contactPhone: { type: String },
        contactEmail: { type: String },
        contactForm: { type: String },
        contactFormLabel: { type: String, default: 'Contact us' },
        searchPage: { type: String },
        languages: { type: Array },
        homepageUrl: { type: String, default: '/' },
        homepageLabel: { type: String, default: 'Homepage' },
        searchLabel: { type: String, default: 'Search' },
        languagesLabel: { type: String, default: 'Select language' },
        menuLabel: { type: String, default: 'Menu' },
        fixedBackground: { type: Boolean, default: false },
        viewAllLabel: { type: String, default: 'View all' },
    },
    data() {
        return {
            windowIsScrolled: false,
            headerHeight: 0
        }
    },
    computed: {
        headerHeightPadding() {
            return {
                'padding-top': this.headerHeight + 'px',
            }
        },
        breadcrumbTopOffset() {
          return {
            'top': this.headerHeight + 'px',
          }
        },
        currentLanguage() {
            if (this.languages && this.languages.length > 1) {
              return this.languages.find(language => language.current === true)
            }

            return null
        }
    },
    mounted() {
        this.handleScroll()
        this.headerHeight = this.$refs.header.offsetHeight

        this.handleDebouncedScroll = debounce(this.handleScroll, 50)
        window.addEventListener('scroll', this.handleDebouncedScroll)
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleDebouncedScroll)
    },
    methods: {
        handleScroll() {
            this.windowIsScrolled = this.fixedBackground || (window.scrollY > this.$refs.header.offsetHeight)
        },
        closeSubMenu(close) {
            close()
        },
        iconHref(key) {
            if (iconMap[key]) {
                return '#' + iconMap[key].id
            }

            return null
        }
    }
}
</script>