<template>
  <a aria-hidden="true" :id="id" :style="headerOffset" class="absolute"></a>
</template>

<script>
export default {
  name: "anchor",
  props: {
    id: { type: String, default: 'news-listing' }
  },
  computed: {
    headerOffset() {
      const header = document.getElementById('page-header')
      this.headerHeight = header ? header.offsetHeight + 20 : 0

      return {
        'margin-top': '-' + this.headerHeight + 'px'
      }
    }
  },
}
</script>