<template>
    <component :is="componentType" :href="linkHref" :target="linkTarget" class="relative px-4 py-1 border rounded group focus:outline-2 focus:outline-offset-4 overflow-clip inline-block" :type="buttonType" :class="cssClassButton">
        <span class="absolute w-full h-full left-0 top-full group-hover:top-0 transition-[top] z-0" :class="cssClassBackground"></span>
        <span class="relative z-1" :class="cssClassText">
            <slot></slot>
        </span>
    </component>
</template>
<script>

export default {
    name: 'nav-button',
    props: {
        type: {
            type: String,
            default: 'button',
            validator(value) {
                return ['button', 'submit', 'anchor'].includes(value)
            }
        },
        color: {
            type: String,
            default: 'primary'
        },
        inverse: {
            type: Boolean,
            default: false
        },
        href: {
            type: String
        },
        target: {
            type: String
        },
        fitContent: { type: Boolean, default: false }
    },
    computed: {
        componentType() {
            if (this.type === 'anchor') {
                return 'a'
            }

            return 'button'
        },
        buttonType() {
            if (this.type === 'anchor') {
                return null
            }

            return this.type
        },
        linkHref() {
            if (this.type === 'anchor') {
                return this.href
            }

            return null
        },
        linkTarget() {
            if (this.type === 'anchor') {
                return this.target
            }

            return null
        },
        cssClassButton() {
            let classList = []

            if (this.fitContent) classList.push('w-fit')

            if (this.inverse)
            {
                // switch(this.color) {
                //     case "secondary":
                //         return "border-secondary-contrast focus:outline-secondary-contrast";
                //     default:
                //         return "border-primary-contrast focus:outline-primary-contrast";
                // }

                if (this.color === 'secondary') classList.push('border-secondary-contrast focus:outline-secondary-contrast')
                else classList.push('border-primary-contrast focus:outline-primary-contrast')
            } else {
                // switch(this.color) {
                //     case "secondary":
                //         return "border-secondary focus:outline-secondary";
                //     default:
                //         return "border-primary focus:outline-primary";
                // }
                if (this.color === 'secondary') classList.push('border-secondary focus:outline-secondary')
                else classList.push('border-primary focus:outline-primary')
            }

            return classList.join(' ')
        },
        cssClassBackground() {
            if (this.inverse)
            {
                switch(this.color) {
                    case "secondary":
                        return "bg-secondary-contrast";
                    default:
                        return "bg-primary-contrast";
                }
            } else {
                switch(this.color) {
                    case "secondary":
                        return "bg-secondary";
                    default:
                        return "bg-primary";
                }
            }
        },
        cssClassText() {
            if (this.inverse) {
                switch(this.color) {
                    case "secondary":
                        return "text-secondary-contrast group-hover:text-secondary";
                    default:
                        return "text-primary-contrast group-hover:text-primary";
                }
            } else {
                switch(this.color) {
                    case "secondary":
                        return "text-secondary group-hover:text-secondary-contrast";
                    default:
                        return "text-primary group-hover:text-primary-contrast";
                }
            }
        }
    }
}
</script>