<template>
    <section :class="['w-full', paddingTop ? 'pt-16' : '', paddingBottom ? 'pb-16' : '', cssBackgroundClass]">
        <slot></slot>
    </section>
</template>
<script>
export default {
    name: 'content-section',
    props: {
        paddingTop: {
            type: Boolean,
            default: true
        },
        paddingBottom: {
            type: Boolean,
            default: true
        },
        background: {
            type: String,
            default: 'white'
        }
    },
    computed: {
        cssBackgroundClass() {
            switch(this.background)
            {
                case 'secondary':
                    return 'bg-secondary'
                case 'black/50':
                    return 'bg-black/60'
                case 'grey':
                    return 'bg-grey-10'
                case "green": 
                    return 'bg-green'
                default:
                    return 'bg-white'
            }
        }
    }
}
</script>