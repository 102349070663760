import { defineAsyncComponent } from 'vue'

import Anchor from '../components/anchor'
import AppFooter from '../components/app-footer'
import AppHeader from '../components/app-header/index.vue'
import BackToTop from '../components/back-to-top'
import Breadcrumb from './breadcrumb'
import ContentContainer from '../components/content-container'
import ContentGrid from '../components/content-grid'
import ContentSection from '../components/content-section'
import ContactUs from '../components/contact-us'
import GridColumn from '../components/content-grid/grid-column.vue'
import MediaSource from '../components/media-source'
import NavButton from '../components/nav-button'
import PageHero from '../components/page-hero'
import ResponsiveImage from '../components/responsive-image'
import SkipLinks from '../components/skip-links'
import UmbracoFormsScriptLoader from './umbracoforms-script-loader'

const BlockQuote = defineAsyncComponent(() => import('../components/block-quote'))
const Carousel = defineAsyncComponent(()=> import('../components/carousel'))
const ContactLocations = defineAsyncComponent(() => import('../components/contact-locations'))
const GoogleMap = defineAsyncComponent(()=> import('../components/map'))
const ImageContent = defineAsyncComponent(() => import('../components/image-content'))
const LinkTile = defineAsyncComponent(() => import('../components/link-tile'))
const LogoSlider = defineAsyncComponent(() => import('../components/logo-slider'))
const MediaSection = defineAsyncComponent(() => import('../components/media-section'))
const NavTitle = defineAsyncComponent(() => import('../components/nav-title'))
const NewsListing = defineAsyncComponent(()=> import('../components/news-listing'))
const ItemDisplay = defineAsyncComponent(()=> import('../components/news-listing/modules/display'))
const ItemPagination = defineAsyncComponent(()=> import('../components/news-listing/modules/pagination'))
//const NewsSwiper = defineAsyncComponent(() => import('../components/news-swiper'))
const NewsTile = defineAsyncComponent(() => import('../components/news-tile'))
const ProfileHero = defineAsyncComponent(() => import('../components/profile-hero'))
const StatTile = defineAsyncComponent(() => import('../components/stat-tile'))
const Tile = defineAsyncComponent(() => import('../components/tile'))
const Search = defineAsyncComponent(()=> import('../components/news-listing/modules/search'))
const ItemOverview = defineAsyncComponent(()=> import('../components/news-listing/modules/overview'))

const ControlField = defineAsyncComponent(()=> import('../components/form/modules/control'))
const ControlGroup = defineAsyncComponent(()=> import('../components/form/modules/control/group'))
const FileField = defineAsyncComponent(()=> import('../components/form/modules/file'))
const Form = defineAsyncComponent(()=> import('../components/form'))
const FormItem = defineAsyncComponent(()=> import('../components/form/modules/form-item'))
const InputField = defineAsyncComponent(()=> import('../components/form/modules/input'))
const SelectField = defineAsyncComponent(()=> import('../components/form/modules/select'))
const TextareaField = defineAsyncComponent(()=> import('../components/form/modules/textarea'))
const ValidationSummary = defineAsyncComponent(()=> import('../components/form/modules/validation-summary'))

const _registerComponents = (vueApp) => {
    if (vueApp) {
        vueApp.component('anchor', Anchor)
        vueApp.component('app-footer', AppFooter)
        vueApp.component('app-header', AppHeader)
        vueApp.component('back-to-top', BackToTop)
        vueApp.component('breadcrumb', Breadcrumb)
        vueApp.component('content-section', ContentSection)
        vueApp.component('nav-button', NavButton)
        vueApp.component('responsive-image', ResponsiveImage)
        vueApp.component('skip-links', SkipLinks)
        
        vueApp.component('block-quote', BlockQuote)
        vueApp.component('carousel', Carousel)
        vueApp.component('contact-locations', ContactLocations)
        vueApp.component('content-container', ContentContainer)
        vueApp.component('content-grid', ContentGrid)
        vueApp.component('contact-us', ContactUs )
        vueApp.component('google-map', GoogleMap)
        vueApp.component('grid-column', GridColumn)
        vueApp.component('image-content', ImageContent)
        vueApp.component('link-tile', LinkTile)
        vueApp.component('logo-slider', LogoSlider)
        vueApp.component('media-section', MediaSection)
        vueApp.component('media-source', MediaSource)
        vueApp.component('nav-title', NavTitle)
        //vueApp.component('news-swiper', NewsSwiper)
        vueApp.component('news-listing', NewsListing)
        vueApp.component('display', ItemDisplay)
        vueApp.component('pagination', ItemPagination)
        vueApp.component('overview', ItemOverview)
        vueApp.component('search', Search)
        
        vueApp.component('news-tile', NewsTile)
        vueApp.component('page-hero', PageHero)
        vueApp.component('profile-hero', ProfileHero)
        vueApp.component('stat-tile', StatTile)
        vueApp.component('tile', Tile)
        
        vueApp.component('form-wrapper', Form)
        vueApp.component('form-item', FormItem)
        vueApp.component('input-field', InputField)
        vueApp.component('control-field', ControlField)
        vueApp.component('control-group', ControlGroup)
        vueApp.component('select-field', SelectField)
        vueApp.component('file-field', FileField)
        vueApp.component('textarea-field', TextareaField)
        vueApp.component('validation-summary', ValidationSummary)
        vueApp.component('umbracoforms-script-loader', UmbracoFormsScriptLoader)
    }
}

export const RegisterComponents = _registerComponents