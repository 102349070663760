<template>
    <div :class="containerClasses">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'content-container',
    props: {
        fluid: {
            type: Boolean,
            default: false
        },
        class: {
            type: String
        },
        paddingX: {
            type: Boolean,
            default: true
        },
        paddingY: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        containerClasses() {
            let cssClass = []

            if (!this.fluid) {
                cssClass.push('container')
            }

            if (this.paddingX && this.paddingY) {
                cssClass.push('p-8 sm:p-8 md:p-16 xl:p-32')
            }
            else {
                if (this.paddingX) {
                    cssClass.push('px-8 sm:px-8 md:px-16 xl:px-32')
                }

                if (this.paddingY) {
                    cssClass.push('py-6 sm:py-8 md:py-16 xl:py-32')
                }
            }
            
            if (this.class) {
                cssClass.push(this.class)
            }

            return cssClass.join(' ')
        }
    }
}
</script>