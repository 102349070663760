import { createApp } from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { RegisterComponents } from './components'

const app = createApp({})

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAP5RVIc-eoKRSOrwKwVOoaU3AdkFuCVfI'
    }
})

RegisterComponents(app)

app.mount(document.getElementById('app'))