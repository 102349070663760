<template>
    <a ref="backTopButton" href="javascript:;" @click="scrollToTop()" class="fixed bottom-8 right-8 drop-shadow group z-10 hidden" :title="title">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 bg-white fill-primary rounded-full p-2 hover:bg-primary hover:fill-white group-focus:bg-primary group-focus:fill-white">
            <use :href="iconHref('chevronUp')" />
        </svg>
    </a>
</template>

<script>
    import IconChevronUp from '../../../assets/icons/chevron-up.svg'

    const iconMap = {
        'chevronUp': IconChevronUp
    }

    export default {
        name: 'back-to-top',

        props: {
            title: { type: String, default: 'Scroll to top of page' }
        },

        created() {
            if (window.matchMedia("(min-width: 1024px)").matches) {
                window.addEventListener('scroll', this.showHideButton)
            }
        },

        mounted() {
            this.showHideButton
        },

        unmounted () {
            window.removeEventListener('scroll', this.showHideButton)
        },

        methods: {
            iconHref(key) {
                if (iconMap[key]) return '#' + iconMap[key].id

                return null
            },

            showHideButton() {
                let backTopButton = this.$refs.backTopButton
                let scrollPosition = window.scrollY
                if (scrollPosition <= 200) {
                    backTopButton.classList.remove('lg:block')
                } else {
                    backTopButton.classList.add('lg:block')
                }
            },

            scrollToTop() {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
        }
    }
</script>