function scrollIntoViewObserver(content, callbackFn) {
    const observer =  new IntersectionObserver(([entry], observer) => {
        if (entry.isIntersecting) {
            callbackFn()
            observer.unobserve(content)
        }
    }, { threshold: 0.1});
    
    observer.observe(content);
}

export {scrollIntoViewObserver};