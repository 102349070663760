<template>
  <nav v-if="hasLinks" class="bg-grey-10 absolute -top-full -left-full focus-within:top-0 focus-within:left-0 w-full z-[55]" :aria-label="skipLabel">
    <div class="flex py-3 px-6">
      <span id="skip-links" class="text-primary font-bold">{{ skipToLabel }}:</span>

      <div>
        <a v-for="(link, index) in links"
           :key="'skiplink-'+ index"
           :id="'skiplink-'+ index"
           :href="link.href"
           :target="link.target"
           class="ml-4 focus-within:text-primary focus-within:underline focus-within:outline-primary hover:text-primary hover:underline"
           :aria-labelledby="'skip-links skiplink-' + index">
          {{ link.title }}
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'skip-links',
    
    props: {
      links: { type: Array, required: true },
      skipLabel: { type: String, default: 'Skip links' },
      skipToLabel: { type: String, default: 'Skip to' }
    },
    
    data() {
      return {
        hasLinks: this.links && this.links.length > 0
      }
    }
  }
</script>