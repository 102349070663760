<template>
  
</template>

<script>
export default {
  name: "umbracoforms-script-loader",
  
  mounted(){
    const src = '/App_Plugins/UmbracoForms/Assets/themes/default/umbracoforms.min.js'
    let scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', src)
    
    const bodyEl = document.querySelector('body')
    
    bodyEl.appendChild(scriptEl)
  }
}
</script>

<style scoped>

</style>