<template>
    <div :class="[spanClass, startClass, endClass]">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'grid-column',
    props: {
        span: {
            type: [Number, Object]
        },
        start: {
            type: [Number, Object]
        },
        end: {
            type: [Number, Object]
        }
    },
    computed: {
        spanClass() {
            if (this.span) {
                if (typeof this.span === 'object') {
                    let colClass = []
                    for (const [key, value] of Object.entries(this.span)) {
                        if (key === 'default') { colClass.push(`col-span-${value}`) }
                        else { colClass.push(`${key}:col-span-${value}`) }
                    }
                    return colClass
                } else {
                    return 'col-span-' + this.span;
                }
            } else {
                return 'col-auto'
            }
        },
        startClass() {
            if (this.start) {
                if (typeof this.start === 'object') {
                    let colClass = []
                    for (const [key, value] of Object.entries(this.start)) {
                        colClass.push(`${key}:col-start-${value}`)
                    }
                    return colClass
                } else {
                    return 'col-start-' + this.start;
                }
            
            }
        },
        endClass() {
            if (this.end) {
                if (typeof this.end === 'object') {
                    let colClass = []
                    for (const [key, value] of Object.entries(this.end)) {
                        colClass.push(`${key}:col-end-${value}`)
                    }
                    return colClass
                } else {
                    return 'col-end-' + this.end;
                }
            
            }
        }
    }
}
</script>