<template>
  <a :href="href" class="fixed z-[45] right-0 top-1/3 transform -translate-y-1/2 px-1 md:px-2 py-4 bg-primary text-white flex items-center justify-center hover:pr-8 transition-all">
    <div style="writing-mode:vertical-rl;">{{text}}</div></a>
</template>
<script>
export default {
    name: 'contact-us',
    props: {
        href: { type: String },
        text: { type: String }
    }
}
</script>