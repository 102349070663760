<template>
    <source :src="mediaSrc" :srcset="imgSrcSet" :width="mediaWidth" :height="mediaHeight" :sizes="sizes" :media="media" :type="type" />
</template>

<script>
export default {
    name: 'media-source',
    props: {
        parent: {
            type: String,
            required: true,
            default: 'picture',
            validator(value) {
                return ['audio', 'picture', 'video'].includes(value)
            }
        },
        type: {
            type: String
        },
        src: {
            type: String
        },
        srcSet: {
            type: String
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        media: {
            type: String
        },
        sizes: {
            type: String
        },
        ratio: {
            type: Number
        },
        generateCropSrcSet: {
            type: Boolean,
            default: false
        },
        generateMultipleSizes: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String
        },
        quality: {
            type: Number,
            default: 80
        },
        focalPoint: {
            type: String || Object  
        },
        anchor: {
            type: String || Object  
        },
        format: {
            type: String
        },
        minWidth: {
            type: Number
        },
        maxWidth: {
            type: Number,
            default: 1920
        },
        stepSize: {
            type: Number,
            default: 160
        }
    },
    computed: {
        mediaSrc() {
            if (!this.src || this.parent == 'picture') {
                return null
            }

            return this.src
        },
        imgSrcSet() {
            if (!this.srcSet && this.parent != 'picture') {
                return null
            }

            if (this.srcSet) {
                return this.srcSet
            }

            if (this.src && this.generateCropSrcSet) {
                if (this.generateMultipleSizes) {
                    return this.generateSourceSet({
                        src: this.src,
                        width: this.width,
                        height: this.height,
                        ratio: this.ratio,
                        mode: this.mode,
                        format: this.format,
                        quality: this.quality,
                        focalPoint: this.focalPoint,
                        anchor: this.anchor,
                        minWidth: this.minWidth,
                        maxWidth: this.maxWidth,
                        stepSize: this.stepSize
                    })
                }

                return this.generateSrc({
                    src: this.src,
                    width: this.width,
                    height: this.height,
                    ratio: this.ratio,
                    mode: this.mode,
                    format: this.format,
                    quality: this.quality,
                    focalPoint: this.focalPoint,
                    anchor: this.anchor
                })
            }
        },
        mediaWidth() {
            if (this.width)
                return this.width

            if (this.ratio)
                return Math.round(this.height / this.ratio)

            return null
        },
        mediaHeight() {
            if (this.height)
                return this.height

            if (this.ratio)
                return Math.round(this.ratio * this.width)

            return null
        }
    },
    methods: {
        generateSrc({
            src,
            width,
            height,
            ratio,
            mode,
            format,
            quality,
            focalPoint,
            anchor
        }) {
            if (!src) {
                return null
            }

            if (!width && !height && !ratio) {
                console.info("No dimensions supplied for image crop", src, width, height, ratio)
                return src
            }

            const imageIsCompatible = src.indexOf('.svg') < 0

            if (!imageIsCompatible) {
                console.info("SVG is not compatible for image crop", src)
                return src
            }

            // TODO: Optimise - split into smaller functions
            const imageSrcParts = []

            // Handling both ImageSharp and ImageProcessor query string values
            // https://imageprocessor.org/imageprocessor-web/imageprocessingmodule/
            // https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html
            if (width) {
                imageSrcParts.push('width=' + width)
            }

            if (!width && height && ratio) {
                width = Math.round(height / ratio)
                imageSrcParts.push('width=' + width)
            }

            if (height) {
                imageSrcParts.push('height=' + height)
            }

            if (!height && width && ratio) {
                height = Math.round(width * ratio)
                imageSrcParts.push('height=' + height)
            }

            // https://imageprocessor.org/imageprocessor-web/imageprocessingmodule/format/
            // https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html#format
            if (format) {
                imageSrcParts.push('format=' + format)
            }

            if (anchor) {
                // https://imageprocessor.org/imageprocessor-web/imageprocessingmodule/resize/
                imageSrcParts.push('anchor=' + anchor)

                // https://docs.sixlabors.com/api/ImageSharp/SixLabors.ImageSharp.Processing.AnchorPositionMode.html
                imageSrcParts.push('ranchor=' + anchor)
            }

            if (focalPoint) {
                let center
                // Parse for focalPoint object e.g. { top: 0.1234, left: 0.5678 }
                if (typeof focalPoint === 'object') {
                    if (focalPoint.top != null && focalPoint.left != null && focalPoint.top != 0.5 && focalPoint.left != 0.5) {
                        center = `${focalPoint.top},${focalPoint.left}`
                    }
                } else if (typeof focalPoint === 'string') {
                    center = focalPoint
                }

                if (center) {
                    // https://imageprocessor.org/imageprocessor-web/imageprocessingmodule/resize/
                    imageSrcParts.push('center=' + center)

                    // https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html
                    imageSrcParts.push('rxy=' + center)
                }
            }

            if (quality) {
                imageSrcParts.push('quality=' + quality)
            }

            if (mode) {
                // https://imageprocessor.org/imageprocessor-web/imageprocessingmodule/resize/
                imageSrcParts.push('mode=' + mode)

                // https://docs.sixlabors.com/api/ImageSharp/SixLabors.ImageSharp.Processing.ResizeMode.html
                imageSrcParts.push('rmode=' + mode)
            }

            // Combine all image URL parts into string
            if (imageSrcParts.length) {
                return src + (src.indexOf('?') >= 0 ? '&' : '?') + imageSrcParts.join('&')
            }

            return src
        },

        generateSourceSet({
            src,
            width,
            height,
            ratio,
            mode,
            format,
            quality,
            focalPoint,
            anchor,
            minWidth,
            maxWidth,
            stepSize
        }) {
            const sourceSets = []
            let stepWidth = (minWidth ? minWidth : stepSize)

            if (!ratio && width && height)
            {
                ratio = height / width
            }

            while (stepWidth <= maxWidth) {
                let stepHeight = null

                if (ratio) {
                    stepHeight = Math.round(ratio * stepWidth)
                }

                const stepUrl = this.generateSrc({
                    src,
                    width: stepWidth,
                    height: stepHeight,
                    ratio,
                    mode,
                    format,
                    quality,
                    focalPoint,
                    anchor
                })

                const sourceSetUrl = stepUrl + ' ' + stepWidth + 'w'
                sourceSets.push(sourceSetUrl)

                stepWidth += stepSize
            }

            return sourceSets
        }
    }
};
</script>