<template>
  <nav class="p-4 sm:px-8" :aria-label="navLabel">
    <ol class="md:flex items-center flex-wrap">
      <li v-for="(breadcrumb, index) in breadcrumbsFiltered" :key="index" class="flex items-center">

        <!-- chevron -->
        <svg v-if="index > 0 && index < breadcrumbsFiltered.length && !breadcrumb.hidden" xmlns="http://www.w3.org/2000/svg" :class="['w-4 h-4 mx-2', svgClass]">
          <use :href="iconHref('chevronRight')" />
        </svg>

        <!-- breadcrumb -->
        <template v-if="breadcrumb.expandButton === undefined">
          <a :class="['whitespace-nowrap text-sm leading-none', {'invisible w-0': breadcrumb.hidden}, textClass]" :href="breadcrumb.href" :aria-label="ariaLabel(breadcrumb.title)" v-if="breadcrumbsFiltered.length !== 1 && index < breadcrumbsFiltered.length - 1">
            {{ elipsifyTitle(breadcrumb.title) }}
          </a>

          <!-- current page -->
          <span :class="['whitespace-nowrap text-sm leading-none', { 'text-white opacity-80': !whiteBackground }, { 'text-grey': whiteBackground }]" aria-current="page" v-else>
            {{ elipsifyTitle(breadcrumb.title) }}
          </span>
        </template>

        <button :aria-label="showAllLabel" class="w-4 h-4" @click="expand" v-if="breadcrumb.expandButton === true">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" :class="['block -translate-y-2 absolute w-4 h-4', svgClass]">
              <use :href="iconHref('ellipsis')" />
            </svg>
          </span>
        </button>
      </li>
    </ol>
  </nav>
</template>

<script>
import IconChevronRight from "../../../assets/icons/chevron-right.svg";
import IconEllipsis from "../../../assets/icons/ellipsis.svg";
const iconMap = {
  'chevronRight': IconChevronRight,
  'ellipsis': IconEllipsis
}
export default {
  name: "breadcrumb",
  props: {
    // Breadcrumbs array:
    // Breadcrumb: (item)
    //    Title: string
    //    Href: string
    breadcrumbs: { type: Array, required: true },
    whiteBackground: { type: Boolean, default: false },
    navLabel: { type: String },
    showAllLabel: { type: String }
  },
  created() {
    let count = this.breadcrumbs.length
    if (count > 3) {
      let expandButtonBreadcrumb = { expandButton: true }
      this.breadcrumbsFiltered.push(this.breadcrumbs[0])
      this.breadcrumbsFiltered.push(expandButtonBreadcrumb)
      for (let i = 1; i < count - 2; i++) {
        let breadcrumb = this.breadcrumbs[i]
        breadcrumb.hidden = true
        this.breadcrumbsFiltered.push(breadcrumb)
      }
      this.breadcrumbsFiltered.push(this.breadcrumbs[count - 2])
      this.breadcrumbsFiltered.push(this.breadcrumbs[count - 1])
      return;
    }
    // normal
    this.breadcrumbsFiltered = this.breadcrumbs
  },
  data() {
    return {
      breadcrumbsFiltered: [],
      maxTitleLength: 24,
    }
  },
  
  computed: {
    textClass() {
      return this.whiteBackground ? 'text-secondary' : 'text-white'
    },
    
    svgClass() {
      return this.whiteBackground ? 'fill-secondary' : 'fill-white'
    }
  },
  
  methods: {
    ariaLabel(text) {
      // only set if title has been elipsified
      return text.length > this.maxTitleLength ? text : null
    },
    expand() {
      this.breadcrumbsFiltered = this.breadcrumbs
      for (let i = 0; i < this.breadcrumbsFiltered.length; i++) {
        let breadcrumb = this.breadcrumbsFiltered[i]
        breadcrumb.hidden = false
      }
    },
    iconHref(key) {
      if (iconMap[key]) {
        return '#' + iconMap[key].id
      }
      return null
    },
    elipsifyTitle(text) {
      return text.length > this.maxTitleLength
          ? text.substring(0, 21) + "..."
          : text
    },
  }
}
</script>