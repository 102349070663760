<template>
    <footer class="bg-black/80 overflow-hidden">
        <content-container :fluid="false">
            <div class="pt-16 pb-8 md:w-3/5">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-16 h-16 fill-primary-40">
                    <use :href="iconHref('logomark')" />
                </svg>
                <h2 class="text-primary-40 mt-4 font-normal text-xl">{{ strapline }}</h2>
            </div>
            <div class="xl:flex items-start">
                <div class="md:flex md:pb-8 xl:w-3/5 xl:pb-0">
                    <div class="pb-8 md:pb-0 md:pr-8 md:w-3/5">
                        <TabGroup>
                            <TabList class="flex gap-2 pb-8">
                                <Tab v-for="(location, index) in locations" :key="'tab-location-' + index"
                                   class="my-auto relative rounded-full border-white border-2 ui-selected:border-4 hover:border-primary-50 focus:outline focus:outline-white focus:outline-offset-2 focus:outline-2 overflow-clip ui-selected:overflow-visible">
                                    <span class="sr-only">{{ location.country }}</span>
                                    <responsive-image :src="location.flag" :width="40" :height="40" format="webp" :generate-crop-src="true" class="w-10 h-10 rounded-full"></responsive-image>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="block absolute top-full left-1/2 -translate-x-1/2 mt-3 w-4 h-4 fill-white">
                                        <use :href="iconHref('chevronDown')" />
                                    </svg>
                                </Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel v-for="(location, locationIndex) in locations" :key="'tab-panel-location-' + locationIndex" class="text-white">
                                    <div class="mb-2 font-bold">{{ location.name }}</div>

                                    <address v-if="location.addressLines && location.addressLines.length > 0" class="not-italic flex flex-col">
                                        <span v-for="(addressLine, lineIndex) in location.addressLines" :key="'addressline-' + locationIndex + '-' + lineIndex">
                                            {{ addressLine }}
                                        </span>
                                    </address>

                                    <div v-if="location.phone" class="mt-4"><a :href="'tel:' + location.phone" class="hover:underline">{{ location.phone }}</a></div>
                                    <div v-if="location.email" class="mt-2"><a :href="'mailto:' + location.email" class="hover:underline">{{ location.email }}</a></div>
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>
                    </div>

                    <div v-if="hasLinks || hasSocials" class="py-8 border-t border-white/50 md:py-0 md:pl-8 md:border-t-0 md:border-l md:w-2/5 flex flex-col">
                        <ul v-if="hasLinks" class="text-white flex flex-col gap-2">
                            <li v-for="(link, index) in links" :key="'link-' + index">
                                <a :href="link.href" :target="link.target" class="hover:underline">{{ link.title }}</a>
                            </li>
                        </ul>

                        <div v-if="hasSocials" class="mt-auto pt-8 flex gap-2">
                            <a v-if="twitter" :href="twitter" target="_blank" class="group w-10 h-10 rounded-full bg-white hover:bg-primary-50 focus:outline focus:outline-white focus:outline-offset-2 focus:outline-2">
                                <span class="sr-only">Twitter</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 p-3 fill-primary group-hover:fill-white">
                                    <use :href="iconHref('iconTwitter')" />
                                </svg>
                            </a>
                            <a v-if="linkedIn" :href="linkedIn" target="_blank" class="group w-10 h-10 rounded-full bg-white hover:bg-primary-50 focus:outline focus:outline-white focus:outline-offset-2 focus:outline-2">
                                <span class="sr-only">LinkedIn</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 p-3 fill-primary group-hover:fill-white">
                                    <use :href="iconHref('iconLinkedIn')" />
                                </svg>
                            </a>
                            <a v-if="youTube" :href="youTube" target="_blank" class="group w-10 h-10 rounded-full bg-white hover:bg-primary-50 focus:outline focus:outline-white focus:outline-offset-2 focus:outline-2">
                                <span class="sr-only">YouTube</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 p-3 fill-primary group-hover:fill-white">
                                    <use :href="iconHref('iconYouTube')" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>

                <div :id="animationId" v-if="!!$slots.info" class="bg-primary px-8 py-8 text-white xl:w-2/5 sm:mx-0 md:p-8 xl:ml-8 prose" :class="[{'lg:transition lg:duration-700 lg:ease-out': showAnimation}, {'lg:translate-y-2/4 lg:opacity-0': !animate && showAnimation}]">
                    <slot name="info"></slot>
                </div>
            </div>

            <div v-if="legalCopy || hasLegalLinks" class="sm:flex text-white text-sm py-16 w-full">
                <div v-if="legalCopy" class="sm:mr-auto">{{ legalCopy }}</div>

                <ul v-if="hasLegalLinks" class="flex gap-4 pt-2 sm:pt-0">
                    <li v-for="(link, index) in legalLinks" :key="'legallink-' + index">
                        <a :href="link.href" :target="link.target" class="hover:underline">{{ link.title }}</a>
                    </li>
                </ul>
            </div>
        </content-container>
    </footer>
</template>

<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import ResponsiveImage from '../responsive-image/'
import ContentContainer from '../content-container/'
</script>

<script>
import Logomark from '../../../assets/icons/logomark.svg'
import IconChevronDown from '../../../assets/icons/chevron-down.svg'
import IconTwitter from '../../../assets/icons/twitter.svg'
import IconLinkedIn from '../../../assets/icons/linkedin.svg'
import IconYouTube from '../../../assets/icons/youtube.svg'
import {scrollIntoViewObserver} from "../../scroll-into-view-observer";

const iconMap = {
    'chevronDown': IconChevronDown,
    'logomark': Logomark,
    'iconTwitter': IconTwitter,
    'iconLinkedIn': IconLinkedIn,
    'iconYouTube': IconYouTube
}

export default {
    name: 'app-header',
    props: {
        strapline: { type: String, required: true },
        locations: {
            type: Array,
            required: true
        },
        links: {
            type: Array,
            required: true
        },
        legalLinks: {
            type: Array,
            required: true
        },
        twitter: '',
        linkedIn: '',
        youTube: '',
        legalCopy: { type: String, default: '' },
        showAnimation: { type: Boolean, default: false },
        animationId: { type: String, default: 'app-footer' }
    },
  
    data() {
        return {
            animate: false    
        }
    },
    
    mounted() {
        if (this.showAnimation) {
          let content = document.getElementById(this.animationId)
          scrollIntoViewObserver(content, this.setAnimate)
        }
    },

    computed: {
        hasLinks() {
            return this.links && this.links.length > 0
        },

        hasLegalLinks() {
            return this.legalLinks && this.legalLinks.length > 0
        },

        hasSocials() {
            return this.twitter || this.linkedIn || this.youTube
        }
    },

    methods: {
        iconHref(key) {
            if (iconMap[key]) {
                return '#' + iconMap[key].id
            }

            return null
        },
        setAnimate() {
           this.animate = true
        },
    }
}
</script>