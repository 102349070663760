<template>
    <div class="grid gap-6" :class="gridColsClass">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'content-grid',
        
        props: {
            columns: { type: Number, default: 12 },
            columnsSm: { type: Number },
            columnsMd: { type: Number },
            columnsLg: { type: Number }
        },

        computed: {
            gridColsClass() {
                let classes = []

                if (this.columns) classes.push('grid-cols-' + this.columns)
                if (this.columnsSm) classes.push('sm:grid-cols-' + this.columnsSm)
                if (this.columnsMd) classes.push('md:grid-cols-' + this.columnsMd)
                if (this.columnsLg) classes.push('lg:grid-cols-' + this.columnsLg)

                return classes.join(' ')
            }
        }
    }
</script>